
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import { HorseType } from '@/store/modules/operations/HorsesModule';
  import Multiselect from '@vueform/multiselect';
  import Attachment from '@/components/common/Attachments.vue';
  import SearchField from '@/components/common/SearchField.vue';

  export default defineComponent({
    name: 'goomer-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
      Attachment,
      SearchField,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('horseOperationUpdating'), [
          translate('operation'),
        ]);
      });

      const data = await store.dispatch(Actions.GET_HORSE, route.params.id);
      const horse = ref(data);
      const attachmentsObj = horse.value.attachments.reduce(
        (acc, attachment) => {
          const documentType = attachment.documentType;

          if (!acc[documentType]) {
            acc[documentType] = [];
          }

          acc[documentType].push(attachment);

          return acc;
        },
        {}
      );

      const docType = ref(horse.value?.attachments[0]?.documentTypeId || 0);

      const ridersId = ref([]);
      ridersId.value = horse.value.horseRiders?.map((rider) => rider.id);

      const removeExistingImage = async (index, id) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });
        if (result.value) {
          horse.value.attachments.splice(index, 1);
          await store.dispatch(Actions.DELETE_HORSE_ATTACHMENT, id);
        }
      };
      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const HandleDateOfBirth = (dateInput) => {
        horse.value.dateOfBirth = dateInput;
      };

      const validationSchema = Yup.object().shape({
        nameAr: Yup.string()
          .required(() => translate('AR_NAME_IS_REQUIRED_FIELD'))
          .label('Name Ar'),
        nameEn: Yup.string()
          .required(() => translate('EN_NAME_IS_REQUIRED_FIELD'))
          .label('Name En'),
        genderId: Yup.string()
          .notOneOf(['0'], () => translate('GENDER_IS_REQUIRED_FIELD'))
          .required(() => translate('GENDER_IS_REQUIRED_FIELD'))
          .label('Gender'),
        statusId: Yup.string()
          .notOneOf(['0'], () => translate('STATUS_IS_REQUIRED_FIELD'))
          .required(() => translate('STATUS_IS_REQUIRED_FIELD'))
          .label('Status'),
        colorId: Yup.string()
          .notOneOf(['0'], () => translate('COLOR_IS_REQUIRED_FIELD'))
          .required(() => translate('COLOR_IS_REQUIRED_FIELD'))
          .label('Color'),
        categoryId: Yup.string()
          .notOneOf(['0'], () => translate('CATEGORY_IS_REQUIRED_FIELD'))
          .required(() => translate('CATEGORY_IS_REQUIRED_FIELD'))
          .label('Categroy'),
        breedId: Yup.string()
          .notOneOf(['0'], () => translate('BREED_IS_REQUIRED_FIELD'))
          .required(() => translate('BREED_IS_REQUIRED_FIELD'))
          .label('Breed'),
        customerId: Yup.string()
          .notOneOf(['0'], () => translate('CUSTOMER_IS_REQUIRED_FIELD'))
          .required(() => translate('CUSTOMER_IS_REQUIRED_FIELD'))
          .label('Customer'),
        horseLocationId: Yup.string()
          .notOneOf(['0'], () => translate('LOCATION_IS_REQUIRED_FIELD'))
          .required(() => translate('LOCATION_IS_REQUIRED_FIELD'))
          .label('Breed'),
        passportTypeId: Yup.string()
          .notOneOf(['0'], () => translate('Passport Type is required field'))
          .required(() => translate('Passport Type is required field'))
          .label('Breed'),
        typeId: Yup.string()
          .notOneOf(['0'], () => translate('TYPE_HORSE_IS_REQUIRED_FIELD'))
          .required(() => translate('TYPE_HORSE_IS_REQUIRED_FIELD'))
          .label('Type'),
        passport: Yup.string()
          .required(() => translate('PASSPORT_NUMBER_IS_REQUIRED_FIELD'))
          .label('Passport Number'),
        dateOfBirth: Yup.date()
          .nullable()
          .required('Date of birth is required'),
        joiningDate: Yup.date()
          .nullable()
          .required('Date of birth is required'),
        ridersId: Yup.string().when(['typeId'], (typeId, schema) => {
          if (isHorseKRCPrivate.value && ridersId.value.length === 0) {
            return schema
              .notOneOf(['0'], () => translate('RIDERS_IS_REQUIRED_FIELD'))
              .required(() => translate('RIDERS_IS_REQUIRED_FIELD'))
              .label('riders');
          } else {
            return schema; // Skip validation when conditions are not met.
          }
        }),
      });

      const onSubmitUpdate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        if (isHorseKRCPrivate.value) {
          values.customers = ridersId.value;
        }

        await store.dispatch(Actions.UPDATE_HORSE, {
          id: horse.value?.id,
          data: values,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_HORSE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
        }
      };
      const onSubmitUpdateAttachment = async (values, cb) => {
        await store.dispatch(Actions.UPLOAD_HORSE_ATTACHMENT, {
          data: values,
          id: horse.value.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_ATTACHMENT'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
          location.reload();
        }
        cb();
      };

      const deleteAttachment = async (id) => {
        const result = await Swal.fire({
          title: translate('You are about to delete this attachment'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          await store.dispatch(Actions.DELETE_HORSE_ATTACHMENT, id);
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          } else {
            Swal.fire({
              text: translate('Successfully deleted attachment'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
            location.reload();
          }
        }
      };

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.NATIONALITY,
        LookupNameIds.STATUS,
        LookupNameIds.HORSE_GENDER,
        LookupNameIds.PASSPORT_TYPE,
        LookupNameIds.HORSE_CATEGORY,
        LookupNameIds.HORSE_COLOR,
        LookupNameIds.HORSE_BREED,
        LookupNameIds.HORSE_TYPE,
        LookupNameIds.CUSTOMER_TYPE,
        LookupNameIds.DOCUMENT_TYPE,
      ]);
      await store.dispatch(Actions.GET_ALL_CUSTOMERS);
      await store.dispatch(Actions.GET_ALL_SETTINGS_HORSE_LOCATIONS);

      const horseLocationList = computed(() => store.getters.allLocationsList);

      const customerList = computed(() => store.getters.allCustomersList);

      const lookupsList = computed(() => store.getters.allLookupsList);

      const nationalityList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.NATIONALITY;
      });
      const statusList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.STATUS;
      });
      const genderList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.HORSE_GENDER;
      });
      const passportTypeList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.PASSPORT_TYPE;
      });
      const colorList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.HORSE_COLOR;
      });

      const breedList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.HORSE_BREED;
      });
      const categoryList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.HORSE_CATEGORY;
      });
      const horseTypeList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.HORSE_TYPE;
      });

      const docTypeList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DOCUMENT_TYPE;
      });

      const isHorseKRCPrivate = computed(() => {
        const type = horseTypeList.find(
          (type) => type.id === horse.value.typeId
        );
        return type ? type.slug === HorseType.KRC_PRIVATE : null;
      });

      return {
        roles: computed(() => store.getters.rolesList),
        onSubmitUpdate,
        onSubmitUpdateAttachment,
        submitButton,
        translate,
        validationSchema,
        goBack,
        horse,
        can,
        HandleDateOfBirth,
        nationalityList,
        passportTypeList,
        categoryList,
        docTypeList,
        breedList,
        colorList,
        horseTypeList,
        statusList,
        genderList,
        customerList,
        horseLocationList,
        isHorseKRCPrivate,
        ridersId,
        removeExistingImage,
        docType,
        attachmentsObj,
        deleteAttachment,
      };
    },
  });
